<template>
  <div>
    <p>I love…</p>
    <p><a id="photography" title="Instagram" href="https://instagram.com/marcrufeis"
        target="_blank">…photography</a></p>
    <p><a id="videography" title="silkframe media" href="https://silkframe-media.de"
        target="_blank">…videography</a></p>
    <p><a id="coding" title="ITMR Developments" href="https://itmr-dev.de" target="_blank">…coding</a></p>
    <p><router-link id="back" title="◄ go back" to="/">◄ back</router-link></p>
  </div>
</template>

<script>
  export default {
    name: 'Activities',
  }
</script>

<style scoped>
p {
  color: #3b3b3b;
  font-size: 300%;
  margin: 0;
}
</style>